import React, {useEffect} from 'react'
import {Navigate, Route, Routes} from 'react-router-dom'
import {connect} from 'react-redux'
import Login from "./containers/Authentication/Login";
import withRouter from "./wrappers/withRouter";
import CacheBuster from "./utils/CacheBuster";
import {initWindowEvents} from "./utils/document-utils";
import {ToastContainer} from "react-toastify";
import NotFound from "./containers/NotFound/NotFound";
import {routes} from "./containers/Layout/routeConfig";
import ManagedRoute from "./utils/ManagedRoute";
import RootModal from "./containers/Modals/RootModal";
import ConfirmDialog from "./containers/ConfirmDialog/ConfirmDialog";
import ReactGA from "react-ga4";

// const GA_MEASUREMENT_ID = "G-F86BZEYWKP"; // prod
const GA_MEASUREMENT_ID = "G-PPLYMB224D"; // preprod
ReactGA.initialize(GA_MEASUREMENT_ID);

const App = ({ isModalOpen }) => {
    useEffect(() => {
        initWindowEvents();
    }, []);

    useEffect(() => {
        ReactGA.send(window.location.pathname + window.location.search);
    });

    return (
        <CacheBuster>
            {({loading, isLatestVersion, refreshCacheAndReload}) => {
                if (loading) {
                    return null;
                } else if (!isLatestVersion) {
                    refreshCacheAndReload();
                }
                return (
                    <>
                        <div className={(isModalOpen ? ' modal-open' : '')}>
                            <Routes>
                                <Route exact path={`/`} element={<Navigate to="/search-patients"/>}/>
                                <Route path="/login" element={<Login />}/>
                                {routes.map((route) => (
                                    <Route path={route.url} element={<ManagedRoute route={route} />} />
                                ))}
                                <Route path="*" element={NotFound} />
                            </Routes>
                        </div>
                        <RootModal />
                        <ConfirmDialog />
                        <ToastContainer
                            position={"top-center"}
                            autoClose={5000}
                            hideProgressBar={true}
                            closeOnClick={true}
                            pauseOnHover={true}
                            draggable={true}
                            progress={undefined}
                        />
                    </>
                )
            }}
        </CacheBuster>
    )
}

const mapDispatchToProps = (state) => ({
})

export default withRouter(connect(null, mapDispatchToProps)(App))
